<template>
  <b-card title="รายงานอายุหนี้">
    <b-row class="delete-margin-title-result">
      <b-col :cols="12">
        <b-form-group
          label-for=""
        >
          <h4>
            {{ titleResult ? titleResult : '-' }}
          </h4>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="delete-margin-table-result">
      <b-col :cols="12">
        <b-form-group
          label-for=""
        >
          <b-table-simple
            caption-top
            bordered
            responsive
            class="position-relative mb-1"
          >
            <b-thead head-variant="light">
              <b-tr>
                <template v-if="typeResult !== 'REPORT4'">
                  <template v-if="$store.state.app.windowWidth >= 1000">
                    <b-th
                      class="text-center"
                      :style="resolveFirstColumnStyle(firstColumnName)"
                    >
                      {{ firstColumnName }}
                    </b-th>
                    <b-th
                      class="text-center"
                      :style="resolveFirstColumnStyle(secondColumnName)"
                    >
                      {{ secondColumnName }}
                    </b-th>
                    <b-th
                      class="text-center"
                      :style="resolveFirstColumnStyle(thirdColumnName)"
                    >
                      {{ thirdColumnName }}
                    </b-th>
                    <b-th class="text-center">
                      เลขทะเบียน
                    </b-th>
                    <b-th
                      class="text-center"
                      style="width: 220px; min-width: 220px;"
                    >
                      ลูกค้า
                    </b-th>
                    <b-th class="text-center">
                      วันที่ 1
                    </b-th>
                    <b-th class="text-center">
                      วันที่ 2
                    </b-th>
                    <b-th class="text-center">
                      วันที่ 3
                    </b-th>
                    <b-th class="text-center">
                      วันที่ 4
                    </b-th>
                    <b-th class="text-center">
                      วันที่ 5
                    </b-th>
                    <b-th class="text-center">
                      มากกว่า 5
                    </b-th>
                    <b-th class="text-center">
                      รวม
                    </b-th>
                    <b-th class="text-center">
                      หมายเลขโทรศัพท์
                    </b-th>
                  </template>
                  <template v-else>
                    <b-th class="text-center">
                      รายละเอียด
                    </b-th>
                  </template>
                </template>
                <template v-else>
                  <b-th class="text-center">
                    วันค้าง
                  </b-th>
                  <b-th class="text-center">
                    เลขทะเบียน
                  </b-th>
                  <b-th class="text-center">
                    ชำระถึง
                  </b-th>
                  <b-th class="text-center">
                    ลูกค้า
                  </b-th>
                  <b-th class="text-center">
                    ยอดรวม
                  </b-th>
                  <b-th class="text-center">
                    พอร์ต
                  </b-th>
                </template>
              </b-tr>
            </b-thead>
            <b-tbody>
              <template v-if="dataResult.length">
                <b-tr
                  v-for="(item, index) in paginateArray(
                    formatDataResult,
                    perPage,
                    currentPage
                  )"
                  :key="index"
                >
                  <template v-if="typeResult !== 'REPORT4'">
                    <template v-if="$store.state.app.windowWidth >= 1000">
                      <b-td class="text-center">
                        <span
                          :style="item.underline ? 'text-decoration: underline;text-decoration-style: double;' : ''"
                        >
                          {{ resolveFirstColumnData(item) }}
                        </span>
                      </b-td>
                      <b-td class="text-center">
                        {{ resolveSecondColumnData(item) }}
                      </b-td>
                      <b-td>
                        {{ resolveThirdColumnData(item) }}
                      </b-td>
                      <b-td class="text-center">
                        {{ item.reg_num }}
                      </b-td>
                      <b-td>
                        <span
                          :style="
                            `${
                              item.underline == '1' ? 'text-decoration: underline;' : ''
                            } ${
                              item.underline == '2'
                                ? 'text-decoration: underline;text-decoration-style: double;'
                                : ''
                            }`
                          "
                        >
                          {{ item.customer_name }}
                        </span>
                      </b-td>
                      <template v-if="$store.state.app.windowWidth >= 1000">
                        <b-td class="text-right">
                          <span
                            :style="
                              `${
                                item.underline == '1' ? 'text-decoration: underline;' : ''
                              } ${
                                item.underline == '2'
                                  ? 'text-decoration: underline;text-decoration-style: double;'
                                  : ''
                              }`
                            "
                          >
                            {{
                              item.ovd1_amt ? Number(item.ovd1_amt).toLocaleString() : ""
                            }}
                          </span>
                        </b-td>
                        <b-td class="text-right">
                          <span
                            :style="
                              `${
                                item.underline == '1' ? 'text-decoration: underline;' : ''
                              } ${
                                item.underline == '2'
                                  ? 'text-decoration: underline;text-decoration-style: double;'
                                  : ''
                              }`
                            "
                          >
                            {{
                              item.ovd2_amt ? Number(item.ovd2_amt).toLocaleString() : ""
                            }}
                          </span>
                        </b-td>
                        <b-td class="text-right">
                          <span
                            :style="
                              `${
                                item.underline == '1' ? 'text-decoration: underline;' : ''
                              } ${
                                item.underline == '2'
                                  ? 'text-decoration: underline;text-decoration-style: double;'
                                  : ''
                              }`
                            "
                          >
                            {{
                              item.ovd3_amt ? Number(item.ovd3_amt).toLocaleString() : ""
                            }}
                          </span>
                        </b-td>
                        <b-td class="text-right">
                          <span
                            :style="
                              `${
                                item.underline == '1' ? 'text-decoration: underline;' : ''
                              } ${
                                item.underline == '2'
                                  ? 'text-decoration: underline;text-decoration-style: double;'
                                  : ''
                              }`
                            "
                          >
                            {{
                              item.ovd4_amt ? Number(item.ovd4_amt).toLocaleString() : ""
                            }}
                          </span>
                        </b-td>
                        <b-td class="text-right">
                          <span
                            :style="
                              `${
                                item.underline == '1' ? 'text-decoration: underline;' : ''
                              } ${
                                item.underline == '2'
                                  ? 'text-decoration: underline;text-decoration-style: double;'
                                  : ''
                              }`
                            "
                          >
                            {{
                              item.ovd5_amt ? Number(item.ovd5_amt).toLocaleString() : ""
                            }}
                          </span>
                        </b-td>
                        <b-td class="text-right">
                          <span
                            :style="
                              `${
                                item.underline == '1' ? 'text-decoration: underline;' : ''
                              } ${
                                item.underline == '2'
                                  ? 'text-decoration: underline;text-decoration-style: double;'
                                  : ''
                              }`
                            "
                          >
                            {{
                              item.ovd6_amt ? Number(item.ovd6_amt).toLocaleString() : ""
                            }}
                          </span>
                        </b-td>
                      </template>
                      <b-td class="text-right">
                        <span
                          :style="
                            `${
                              item.underline == '1' ? 'text-decoration: underline;' : ''
                            } ${
                              item.underline == '2'
                                ? 'text-decoration: underline;text-decoration-style: double;'
                                : ''
                            }`
                          "
                        >
                          {{ item.ovd_sum != '' ? Number(item.ovd_sum).toLocaleString() : '' }}
                        </span>
                      </b-td>
                      <b-td class="text-center">
                        {{ item.telephone }}
                      </b-td>
                    </template>
                    <template v-else>
                      <b-td>
                        <template v-if="item.reg_num">
                          <span class="d-block">
                            {{ firstColumnName }} : {{ resolveFirstColumnData(item) }}
                          </span>
                          <span class="d-block">
                            {{ secondColumnName }} : {{ resolveSecondColumnData(item) }}
                          </span>
                          <span class="d-block">
                            {{ thirdColumnName }} : {{ resolveThirdColumnData(item) }}
                          </span>
                          <span
                            class="d-block"
                            style="margin-top: 15px;"
                          >
                            เลขทะเบียน : {{ item.reg_num }}
                          </span>
                          <span
                            class="d-block"
                            style="margin-top: 15px;"
                          >
                            ลูกค้า : {{ item.customer_name }} {{ item.telephone ? `(${item.telephone})` : '' }}
                          </span>
                          <span class="d-block">
                            ยอดรวม : {{ item.ovd_sum != '' ? Number(item.ovd_sum).toLocaleString() : '' }}.-
                          </span>
                        </template>
                        <template v-else>
                          <span
                            class="d-block"
                            style="font-weight: bold;"
                          >
                            {{ firstColumnName }} : {{ resolveFirstColumnData(item) }}
                          </span>
                          <span
                            class="d-block"
                            style="font-weight: bold;"
                          >
                            จำนวน : {{ item.customer_name }}
                          </span>
                          <span
                            class="d-block"
                            style="font-weight: bold;"
                          >
                            ยอดรวม : {{ item.ovd_sum != '' ? Number(item.ovd_sum).toLocaleString() : '' }}.-
                          </span>
                        </template>
                      </b-td>
                    </template>
                  </template>
                  <template v-else>
                    <b-td class="text-center">
                      {{ item.overdue_day }}
                    </b-td>
                    <b-td class="text-center">
                      {{ item.reg_num }}
                    </b-td>
                    <b-td class="text-center">
                      {{ item.last_paid_rent_date }}
                    </b-td>
                    <b-td>
                      <span
                        :style="
                          `${
                            item.underline == '1' ? 'text-decoration: underline;' : ''
                          } ${
                            item.underline == '2'
                              ? 'text-decoration: underline;text-decoration-style: double;'
                              : ''
                          }`
                        "
                      >
                        {{ item.customer_name }}
                      </span>
                    </b-td>
                    <b-td class="text-right">
                      <span
                        :style="
                          `${
                            item.underline == '1' ? 'text-decoration: underline;' : ''
                          } ${
                            item.underline == '2'
                              ? 'text-decoration: underline;text-decoration-style: double;'
                              : ''
                          }`
                        "
                      >
                        {{ item.ovd_sum != '' ? Number(item.ovd_sum).toLocaleString() : '' }}
                      </span>
                    </b-td>
                    <b-td class="text-center">
                      {{ item.port }}
                    </b-td>
                  </template>
                </b-tr>
              </template>
            </b-tbody>
            <b-tfoot head-variant="light">
              <b-tr>
                <template v-if="typeResult !== 'REPORT4'">
                  <template v-if="$store.state.app.windowWidth >= 1000">
                    <b-th
                      class="text-center"
                      :style="resolveFirstColumnStyle(firstColumnName)"
                    >
                      {{ firstColumnName }}
                    </b-th>
                    <b-th
                      class="text-center"
                      :style="resolveFirstColumnStyle(secondColumnName)"
                    >
                      {{ secondColumnName }}
                    </b-th>
                    <b-th
                      class="text-center"
                      :style="resolveFirstColumnStyle(thirdColumnName)"
                    >
                      {{ thirdColumnName }}
                    </b-th>
                    <b-th class="text-center">
                      เลขทะเบียน
                    </b-th>
                    <b-th
                      class="text-center"
                      style="width: 220px; min-width: 220px;"
                    >
                      ลูกค้า
                    </b-th>
                    <b-th class="text-center">
                      วันที่ 1
                    </b-th>
                    <b-th class="text-center">
                      วันที่ 2
                    </b-th>
                    <b-th class="text-center">
                      วันที่ 3
                    </b-th>
                    <b-th class="text-center">
                      วันที่ 4
                    </b-th>
                    <b-th class="text-center">
                      วันที่ 5
                    </b-th>
                    <b-th class="text-center">
                      มากกว่า 5
                    </b-th>
                    <b-th class="text-center">
                      รวม
                    </b-th>
                    <b-th class="text-center">
                      หมายเลขโทรศัพท์
                    </b-th>
                  </template>
                  <template v-else>
                    <b-th class="text-center">
                      รายละเอียด
                    </b-th>
                  </template>
                </template>
                <template v-else>
                  <b-th class="text-center">
                    วันค้าง
                  </b-th>
                  <b-th class="text-center">
                    เลขทะเบียน
                  </b-th>
                  <b-th class="text-center">
                    ชำระถึง
                  </b-th>
                  <b-th class="text-center">
                    ลูกค้า
                  </b-th>
                  <b-th class="text-center">
                    ยอดรวม
                  </b-th>
                  <b-th class="text-center">
                    พอร์ต
                  </b-th>
                </template>
              </b-tr>
            </b-tfoot>
          </b-table-simple>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="formatDataResult.length"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label-for=""
        >
          <div style="display: inline;">
            <b-button
              variant="primary"
              @click="fncClickExportExcel"
            >
              ดาวน์โหลด Excel
            </b-button>
          </div>
          <div
            v-if="typeResult !== 'REPORT4'"
            class="ml-1"
            style="display: inline;"
          >
            <b-button
              variant="primary"
              @click="fncClickExportPDF"
            >
              ดาวน์โหลด PDF
            </b-button>
          </div>
          <div
            :class="Number($store.state.app.windowWidth) > 511 ? 'ml-1' : 'mt-1 d-block'"
            style="display: inline;"
          >
            <b-button
              variant="outline-secondary"
              @click="fncResetForm()"
            >
              ยกเลิก
            </b-button>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BButton, BTableSimple, BThead, BTr, BTh, BTbody, BTd, BTfoot, BPagination,
} from 'bootstrap-vue'
import { ref, computed } from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import XLSX from 'xlsx' // import xlsx
// eslint-disable-next-line import/named
import { THSarabunNewNormal, defaultVFS } from '@/jspdf/THSarabunNew-normal'
// eslint-disable-next-line import/named
import { THSarabunNewBold } from '@/jspdf/THSarabunNew-bold'
import { jsPDF } from 'jspdf'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BTfoot,
    BPagination,
  },

  props: {
    typeResult: {
      type: String,
      required: true,
    },

    dataResult: {
      type: [Object, Array],
      required: true,
    },
  },

  setup(props, { emit }) {
    // ประกาศตัวแปรสำหรับเก็บค่า
    const perPage = ref(25)
    const currentPage = ref(1)
    const accesstime = new Date()

    /* ========== ส่วนตัวแปร Computed ========== */
    // กำหนดรูปแบบการแสดงผลให้กับ DataResult

    const formatDataResult = computed(() => {
      // eslint-disable-next-line prefer-const
      let setFormatDataResult = []

      let getSumCondition = 0
      let getSumReportOvd1 = 0
      let getSumReportOvd2 = 0
      let getSumReportOvd3 = 0
      let getSumReportOvd4 = 0
      let getSumReportOvd5 = 0
      let getSumReportOvd6 = 0
      let getSumReportOvdSum = 0

      let getSumAllCondition = 0
      let getSumAllReportOvd1 = 0
      let getSumAllReportOvd2 = 0
      let getSumAllReportOvd3 = 0
      let getSumAllReportOvd4 = 0
      let getSumAllReportOvd5 = 0
      let getSumAllReportOvd6 = 0
      let getSumAllReportOvdSum = 0

      Object.keys(props.dataResult).forEach(key => {
        const item = props.dataResult[key]
        const itemNext = props.dataResult[Number(key) + 1]

        const getOvdSum = item.ovd1_amt + item.ovd2_amt + item.ovd3_amt + item.ovd4_amt + item.ovd5_amt + item.ovd6_amt

        setFormatDataResult.push({
          port: item.cpnbrncod_desc,
          overdue_day: item.overdue_day,
          reg_num: item.reg_num,
          last_paid_rent_date: item.last_paid_rent_date,
          customer_name: item.customer_name,
          ovd1_amt: item.ovd1_amt,
          ovd2_amt: item.ovd2_amt,
          ovd3_amt: item.ovd3_amt,
          ovd4_amt: item.ovd4_amt,
          ovd5_amt: item.ovd5_amt,
          ovd6_amt: item.ovd6_amt,
          telephone: item.telephone,
          ovd_sum: getOvdSum,
          sales_name: item.sales_name,
          underline: 0,
        })

        getSumCondition += 1
        getSumReportOvd1 += item.ovd1_amt
        getSumReportOvd2 += item.ovd2_amt
        getSumReportOvd3 += item.ovd3_amt
        getSumReportOvd4 += item.ovd4_amt
        getSumReportOvd5 += item.ovd5_amt
        getSumReportOvd6 += item.ovd6_amt
        getSumReportOvdSum += getOvdSum

        getSumAllCondition += 1
        getSumAllReportOvd1 += item.ovd1_amt
        getSumAllReportOvd2 += item.ovd2_amt
        getSumAllReportOvd3 += item.ovd3_amt
        getSumAllReportOvd4 += item.ovd4_amt
        getSumAllReportOvd5 += item.ovd5_amt
        getSumAllReportOvd6 += item.ovd6_amt
        getSumAllReportOvdSum += getOvdSum

        if ((itemNext && (((props.typeResult === 'REPORT1' || props.typeResult === 'REPORT2') && item.cpnbrncod_desc !== itemNext.cpnbrncod_desc) || (props.typeResult === 'REPORT3' && item.sales_name !== itemNext.sales_name))) || !itemNext) {
          setFormatDataResult.push({
            port: props.typeResult === 'REPORT1' || props.typeResult === 'REPORT2' ? `รวม ${item.cpnbrncod_desc}` : '',
            overdue_day: '',
            reg_num: '',
            customer_name: `${getSumCondition} ราย`,
            ovd1_amt: getSumReportOvd1,
            ovd2_amt: getSumReportOvd2,
            ovd3_amt: getSumReportOvd3,
            ovd4_amt: getSumReportOvd4,
            ovd5_amt: getSumReportOvd5,
            ovd6_amt: getSumReportOvd6,
            telephone: '',
            ovd_sum: getSumReportOvdSum,
            sales_name: props.typeResult === 'REPORT3' ? `รวม ${item.sales_name}` : '',
            underline: 1,
          })

          getSumCondition = 0
          getSumReportOvd1 = 0
          getSumReportOvd2 = 0
          getSumReportOvd3 = 0
          getSumReportOvd4 = 0
          getSumReportOvd5 = 0
          getSumReportOvd6 = 0
          getSumReportOvdSum = 0
        }

        if (!itemNext) {
          setFormatDataResult.push({
            port: props.typeResult === 'REPORT1' || props.typeResult === 'REPORT2' ? 'รวมทั้งหมด' : '',
            overdue_day: '',
            reg_num: '',
            customer_name: `${getSumAllCondition} ราย`,
            ovd1_amt: getSumAllReportOvd1,
            ovd2_amt: getSumAllReportOvd2,
            ovd3_amt: getSumAllReportOvd3,
            ovd4_amt: getSumAllReportOvd4,
            ovd5_amt: getSumAllReportOvd5,
            ovd6_amt: getSumAllReportOvd6,
            telephone: '',
            ovd_sum: getSumAllReportOvdSum,
            sales_name: props.typeResult === 'REPORT3' ? 'รวมทั้งหมด' : '',
            underline: 2,
          })
        }
      })

      return setFormatDataResult
    })
    /* ========== ส่วนตัวแปร Computed ========== */

    // eslint-disable-next-line consistent-return
    const firstColumnName = computed(() => {
      if (props.typeResult === 'REPORT1') return 'พอร์ต'
      if (props.typeResult === 'REPORT2') return 'พอร์ต'
      if (props.typeResult === 'REPORT3') return 'เซลล์'
    })

    // eslint-disable-next-line consistent-return
    const secondColumnName = computed(() => {
      if (props.typeResult === 'REPORT1') return 'วันค้าง'
      if (props.typeResult === 'REPORT2') return 'เซลล์'
      if (props.typeResult === 'REPORT3') return 'วันค้าง'
    })

    // eslint-disable-next-line consistent-return
    const thirdColumnName = computed(() => {
      if (props.typeResult === 'REPORT1') return 'เซลล์'
      if (props.typeResult === 'REPORT2') return 'วันค้าง'
      if (props.typeResult === 'REPORT3') return 'พอร์ต'
    })

    // eslint-disable-next-line consistent-return
    const resolveFirstColumnData = data => {
      if (props.typeResult === 'REPORT1') return data.port
      if (props.typeResult === 'REPORT2') return data.port
      if (props.typeResult === 'REPORT3') return data.sales_name
    }

    // eslint-disable-next-line consistent-return
    const resolveSecondColumnData = data => {
      if (props.typeResult === 'REPORT1') return data.overdue_day
      if (props.typeResult === 'REPORT2') return data.sales_name
      if (props.typeResult === 'REPORT3') return data.overdue_day
    }

    // eslint-disable-next-line consistent-return
    const resolveThirdColumnData = data => {
      if (props.typeResult === 'REPORT1') return data.sales_name
      if (props.typeResult === 'REPORT2') return data.overdue_day
      if (props.typeResult === 'REPORT3') return data.port
    }

    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */
    /* ฟังก์ชั่นเมื่อกดปุ่มยกเลิก จะทำการ reset ข้อมูล [Result] */
    const fncResetForm = () => {
      emit('reset-result')
    }

    const fncClickExportExcel = () => {
      let setJsonValue = []

      Object.keys(formatDataResult.value).forEach(key => {
        const item = formatDataResult.value[key]
        if (props.typeResult === 'REPORT4') {
          setJsonValue[key] = ({
            วันค้าง: item.overdue_day,
            เลขทะเบียน: item.reg_num,
            ชำระถึง: item.last_paid_rent_date,
            ลูกค้า: item.customer_name,
            ยอดรวม: item.ovd_sum,
            พอร์ต: item.port,
          })
        } else {
          setJsonValue[key] = ({
            firstColumnName: resolveFirstColumnData(item),
            secondColumnName: resolveSecondColumnData(item),
            thirdColumnName: resolveThirdColumnData(item),
            เลขทะเบียน: item.reg_num,
            ลูกค้า: item.customer_name,
            'วันที่ 1': item.ovd1_amt,
            'วันที่ 2': item.ovd2_amt,
            'วันที่ 3': item.ovd3_amt,
            'วันที่ 4': item.ovd4_amt,
            'วันที่ 5': item.ovd5_amt,
            'มากกว่า 5': item.ovd6_amt,
            รวม: item.ovd_sum,
            หมายเลขโทรศัพท์: item.telephone,
          })
        }
      })

      // เปลี่ยนชื่อ Column ตามประเภท Report
      setJsonValue = JSON.parse(JSON.stringify(setJsonValue).split('"firstColumnName":').join(`"${firstColumnName.value}":`))
      setJsonValue = JSON.parse(JSON.stringify(setJsonValue).split('"secondColumnName":').join(`"${secondColumnName.value}":`))
      setJsonValue = JSON.parse(JSON.stringify(setJsonValue).split('"thirdColumnName":').join(`"${thirdColumnName.value}":`))

      const dataWS = XLSX.utils.json_to_sheet(setJsonValue)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, dataWS)
      XLSX.writeFile(wb, `รายงานอายุหนี้_${moment(accesstime).format('YYYY-MM-DD')}.xlsx`)
    }

    const fncClickExportPDF = () => {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF('l', 'mm', [297, 210])

      doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal')
      doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold')

      doc.setFontSize(22)
      doc.setFont('THSarabunNew', 'bold')

      doc.text(148.5, 20, 'รายงานอายุหนี้', 'center')

      // doc.setFontSize(18)
      // doc.text(10, 33, `${props.titleResult ? props.titleResult : ''}`)

      doc.setLineWidth(0.1)
      doc.line(10, 35, 287, 35) // horizontal line (เส้นแนวนอน)

      doc.line(10, 35, 10, 42) // vertical line

      if (props.typeResult === 'REPORT1') {
        doc.line(35, 35, 35, 42) // vertical line Column 1
        doc.line(50, 35, 50, 42) // vertical line Column 2
      }

      if (props.typeResult === 'REPORT2') {
        doc.line(35, 35, 35, 42) // vertical line Column 1
        doc.line(70, 35, 70, 42) // vertical line Column 2
      }

      if (props.typeResult === 'REPORT3') {
        doc.line(45, 35, 45, 42) // vertical line Column 1
        doc.line(60, 35, 60, 42) // vertical line Column 2
      }

      doc.line(85, 35, 85, 42) // vertical line Column 3
      doc.line(105, 35, 105, 42) // vertical line เลขทะเบียน
      doc.line(140, 35, 140, 42) // vertical line ลูกค้า
      doc.line(157, 35, 157, 42) // vertical line วันที่ 1
      doc.line(174, 35, 174, 42) // vertical line วันที่ 2
      doc.line(191, 35, 191, 42) // vertical line วันที่ 3
      doc.line(208, 35, 208, 42) // vertical line วันที่ 4
      doc.line(225, 35, 225, 42) // vertical line วันที่ 5
      doc.line(242, 35, 242, 42) // vertical line มากกว่า 5
      doc.line(259, 35, 259, 42) // vertical line รวม
      doc.line(287, 35, 287, 42) // vertical line เบอร์โทร

      doc.line(10, 42, 287, 42) // horizontal line (เส้นแนวนอน)

      doc.setFontSize(12)
      if (props.typeResult === 'REPORT1') {
        doc.text(22.5, 40, firstColumnName.value, 'center')
        doc.text(42.5, 40, secondColumnName.value, 'center')
        doc.text(67.5, 40, thirdColumnName.value, 'center')
      }

      if (props.typeResult === 'REPORT2') {
        doc.text(22.5, 40, firstColumnName.value, 'center')
        doc.text(52.5, 40, secondColumnName.value, 'center')
        doc.text(77.5, 40, thirdColumnName.value, 'center')
      }

      if (props.typeResult === 'REPORT3') {
        doc.text(27.5, 40, firstColumnName.value, 'center')
        doc.text(52.5, 40, secondColumnName.value, 'center')
        doc.text(72.5, 40, thirdColumnName.value, 'center')
      }

      doc.text(95, 40, 'เลขทะเบียน', 'center')
      doc.text(122.5, 40, 'ลูกค้า', 'center')
      doc.text(148.5, 40, 'วันที่ 1', 'center')
      doc.text(165.5, 40, 'วันที่ 2', 'center')
      doc.text(182.5, 40, 'วันที่ 3', 'center')
      doc.text(199.5, 40, 'วันที่ 4', 'center')
      doc.text(216.5, 40, 'วันที่ 5', 'center')
      doc.text(233.5, 40, 'มากกว่า 5', 'center')
      doc.text(250.5, 40, 'รวม', 'center')
      doc.text(273, 40, 'เบอร์โทร', 'center')

      doc.setFont('THSarabunNew', 'normal')
      let currentX = 42

      Object.keys(formatDataResult.value).forEach(key => {
        const item = formatDataResult.value[key]
        doc.line(10, currentX, 10, currentX + 6) // vertical line

        if (props.typeResult === 'REPORT1') {
          doc.text(22.5, currentX + 4, `${resolveFirstColumnData(item)}`, 'center')
          doc.line(35, currentX, 35, currentX + 6) // vertical line Column 1

          doc.text(42.5, currentX + 4, `${resolveSecondColumnData(item)}`, 'center')
          doc.line(50, currentX, 50, currentX + 6) // vertical line Column 2

          doc.text(51, currentX + 4, `${resolveThirdColumnData(item)}`)
        // eslint-disable-next-line brace-style
        }
        else if (props.typeResult === 'REPORT2') {
          doc.text(22.5, currentX + 4, `${resolveFirstColumnData(item)}`, 'center')
          doc.line(35, currentX, 35, currentX + 6) // vertical line Column 1

          doc.text(36, currentX + 4, `${resolveSecondColumnData(item)}`)
          doc.line(70, currentX, 70, currentX + 6) // vertical line Column 2

          doc.text(77.5, currentX + 4, `${resolveThirdColumnData(item)}`, 'center')
        // eslint-disable-next-line brace-style
        }
        else if (props.typeResult === 'REPORT3') {
          doc.text(11, currentX + 4, `${resolveFirstColumnData(item)}`)
          doc.line(45, currentX, 45, currentX + 6) // vertical line Column 1

          doc.text(52.5, currentX + 4, `${resolveSecondColumnData(item)}`, 'center')
          doc.line(60, currentX, 60, currentX + 6) // vertical line Column 2

          doc.text(72.5, currentX + 4, `${resolveThirdColumnData(item)}`, 'center')
        }

        doc.line(85, currentX, 85, currentX + 6) // vertical line Column 3

        doc.text(95, currentX + 4, `${item.reg_num}`, 'center')
        doc.line(105, currentX, 105, currentX + 6) // vertical line เลขทะเบียน

        doc.text(106, currentX + 4, `${item.customer_name}`)
        doc.line(140, currentX, 140, currentX + 6) // vertical line ลูกค้า

        doc.text(156, currentX + 4, `${item.ovd1_amt ? Number(item.ovd1_amt).toLocaleString() : ''}`, 'right')
        doc.line(157, currentX, 157, currentX + 6) // vertical line วันที่ 1

        doc.text(173, currentX + 4, `${item.ovd2_amt ? Number(item.ovd2_amt).toLocaleString() : ''}`, 'right')
        doc.line(174, currentX, 174, currentX + 6) // vertical line วันที่ 2

        doc.text(190, currentX + 4, `${item.ovd3_amt ? Number(item.ovd3_amt).toLocaleString() : ''}`, 'right')
        doc.line(191, currentX, 191, currentX + 6) // vertical line วันที่ 3

        doc.text(207, currentX + 4, `${item.ovd4_amt ? Number(item.ovd4_amt).toLocaleString() : ''}`, 'right')
        doc.line(208, currentX, 208, currentX + 6) // vertical line วันที่ 4

        doc.text(224, currentX + 4, `${item.ovd5_amt ? Number(item.ovd5_amt).toLocaleString() : ''}`, 'right')
        doc.line(225, currentX, 225, currentX + 6) // vertical line วันที่ 5

        doc.text(241, currentX + 4, `${item.ovd6_amt ? Number(item.ovd6_amt).toLocaleString() : ''}`, 'right')
        doc.line(242, currentX, 242, currentX + 6) // vertical line มากกว่า 5

        doc.text(258, currentX + 4, `${item.ovd_sum ? Number(item.ovd_sum).toLocaleString() : ''}`, 'right')
        doc.line(259, currentX, 259, currentX + 6) // vertical line รวม

        doc.text(273, currentX + 4, `${item.telephone ? item.telephone : ''}`, 'center')
        doc.line(287, currentX, 287, currentX + 6) // vertical line เบอร์โทร

        doc.line(10, currentX + 6, 287, currentX + 6) // horizontal line (เส้นแนวนอน)

        currentX += 6

        if (currentX >= 190) {
          doc.addPage()

          doc.setFontSize(22)
          doc.setFont('THSarabunNew', 'bold')

          doc.text(148.5, 20, 'รายงานอายุหนี้', 'center')

          // doc.setFontSize(18)
          // doc.text(10, 33, `${props.titleResult}`)

          doc.line(10, 35, 287, 35) // horizontal line (เส้นแนวนอน)

          doc.line(10, 35, 10, 42) // vertical line
          if (props.typeResult === 'REPORT1') {
            doc.line(35, 35, 35, 42) // vertical line Column 1
            doc.line(50, 35, 50, 42) // vertical line Column 2
          }

          if (props.typeResult === 'REPORT2') {
            doc.line(35, 35, 35, 42) // vertical line Column 1
            doc.line(70, 35, 70, 42) // vertical line Column 2
          }

          if (props.typeResult === 'REPORT3') {
            doc.line(45, 35, 45, 42) // vertical line Column 1
            doc.line(60, 35, 60, 42) // vertical line Column 2
          }

          doc.line(85, 35, 85, 42) // vertical line Column 3
          doc.line(105, 35, 105, 42) // vertical line เลขทะเบียน
          doc.line(140, 35, 140, 42) // vertical line ลูกค้า
          doc.line(157, 35, 157, 42) // vertical line วันที่ 1
          doc.line(174, 35, 174, 42) // vertical line วันที่ 2
          doc.line(191, 35, 191, 42) // vertical line วันที่ 3
          doc.line(208, 35, 208, 42) // vertical line วันที่ 4
          doc.line(225, 35, 225, 42) // vertical line วันที่ 5
          doc.line(242, 35, 242, 42) // vertical line มากกว่า 5
          doc.line(259, 35, 259, 42) // vertical line รวม
          doc.line(287, 35, 287, 42) // vertical line เบอร์โทร

          doc.line(10, 42, 287, 42) // horizontal line (เส้นแนวนอน)

          doc.setFontSize(12)
          if (props.typeResult === 'REPORT1') {
            doc.text(22.5, 40, firstColumnName.value, 'center')
            doc.text(42.5, 40, secondColumnName.value, 'center')
            doc.text(67.5, 40, thirdColumnName.value, 'center')
          }

          if (props.typeResult === 'REPORT2') {
            doc.text(22.5, 40, firstColumnName.value, 'center')
            doc.text(52.5, 40, secondColumnName.value, 'center')
            doc.text(77.5, 40, thirdColumnName.value, 'center')
          }

          if (props.typeResult === 'REPORT3') {
            doc.text(27.5, 40, firstColumnName.value, 'center')
            doc.text(52.5, 40, secondColumnName.value, 'center')
            doc.text(72.5, 40, thirdColumnName.value, 'center')
          }

          doc.text(95, 40, 'เลขทะเบียน', 'center')
          doc.text(122.5, 40, 'ลูกค้า', 'center')
          doc.text(148.5, 40, 'วันที่ 1', 'center')
          doc.text(165.5, 40, 'วันที่ 2', 'center')
          doc.text(182.5, 40, 'วันที่ 3', 'center')
          doc.text(199.5, 40, 'วันที่ 4', 'center')
          doc.text(216.5, 40, 'วันที่ 5', 'center')
          doc.text(233.5, 40, 'มากกว่า 5', 'center')
          doc.text(250.5, 40, 'รวม', 'center')
          doc.text(273, 40, 'เบอร์โทร', 'center')

          currentX = 42

          doc.setFont('THSarabunNew', 'normal')
        }
      })

      const pages = doc.internal.getNumberOfPages()
      const pageWidth = doc.internal.pageSize.width
      const pageHeight = doc.internal.pageSize.height

      // eslint-disable-next-line no-plusplus
      for (let j = 1; j < pages + 1; j++) {
        const horizontalPos = pageWidth / 2
        const verticalPos = pageHeight - 10
        doc.setPage(j)
        doc.setFontSize(14)
        doc.text(`${j} / ${pages}`, horizontalPos, verticalPos, {
          align: 'center',
        })
      }

      doc.output('dataurlnewwindow') // คำสั่ง Export *** dataurlnewwindow = เปิดแท็บใหม่
    }
    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */

    /* ========== ส่วนการทำงานแสดงผล UI ========== */
    /* คำสั่งแบ่งหน้า */
    const paginateArray = (array, getPerPage, page) => array.slice((page - 1) * getPerPage, page * getPerPage)

    // eslint-disable-next-line consistent-return
    const resolveFirstColumnStyle = column => {
      if (column === 'พอร์ต') return 'min-width: 120px; max-width: 150px;'
      if (column === 'วันค้าง') return 'min-width: 80px;'
      if (column === 'เซลล์') return 'min-width: 160px; max-width: 240px;'
    }
    /* ========== ส่วนการทำงานแสดงผล UI ========== */

    // eslint-disable-next-line consistent-return
    const titleResult = computed(() => {
      if (props.typeResult === 'REPORT1') return '1. เรียงตาม พอร์ต > วันค้าง > เซลล์'
      if (props.typeResult === 'REPORT2') return '2. เรียงตาม พอร์ต > เซลล์ > วันค้าง'
      if (props.typeResult === 'REPORT3') return '3. เรียงตาม เซลล์ > วันค้าง > พอร์ต'
      if (props.typeResult === 'REPORT4') return props.dataResult.length ? props.dataResult[0].sales_name : ''
    })

    return {
      // ประกาศตัวแปรสำหรับเก็บค่า
      perPage,
      currentPage,

      // ส่วนโค้ดการทำงานฟังก์ชั่น
      fncResetForm,
      fncClickExportExcel,
      fncClickExportPDF,

      // ส่วนการทำงานแสดงผล UI
      formatDataResult,
      paginateArray,
      resolveFirstColumnStyle,

      // Export import Lib
      THSarabunNewNormal,
      THSarabunNewBold,
      defaultVFS,

      // ตัวแปร Computed
      firstColumnName,
      secondColumnName,
      thirdColumnName,
      titleResult,

      resolveFirstColumnData,
      resolveSecondColumnData,
      resolveThirdColumnData,
    }
  },
}
</script>

<style lang="scss">
/* for local only */
// .delete-margin-title-result {
//   margin-bottom: -40px;
// }

// .delete-margin-table-result {
//   margin-bottom: -40px;
// }

.table th, .table td {
  padding: 0.4rem 0.8rem;
}

@media (min-width: 600px)
{
  .table th, .table td {
    padding: 0.72rem 2rem;
  }
}
</style>
