<template>
  <div>
    <template v-if="!dataResult.length">
      <b-card title="รายงานอายุหนี้">
        <aging-report-popup-branch
          :temp-search-branch="tempSearchBranch"
          :is-popup-branch-active.sync="isPopupBranchActive"
          @select-item="fncSelectBranch"
        />
        <aging-report-popup-sales
          :temp-search-sales="tempSearchSales"
          :is-popup-sales-active.sync="isPopupSalesActive"
          @select-item="fncSelectSales"
        />
        <!-- <b-modal
          ref="refModalDatePickerOverDueDateStart"
          centered
          title="วันเริ่มต้นที่ค้างชำระ"
          hide-footer
          size="sm"
          modal-class="modalDatePicker"
        >
          <v-date-picker
            v-model="overDueDateStart"
            class="mt-6"
            locale="th"
            @input="refModalDatePickerOverDueDateStart.hide()"
          />
        </b-modal>
        <b-modal
          ref="refModalDatePickerOverDueDateEnd"
          centered
          title="วันสิ้นสุดที่ค้างชำระ"
          hide-footer
          size="sm"
          modal-class="modalDatePicker"
        >
          <v-date-picker
            v-model="overDueDateEnd"
            class="mt-6"
            locale="th"
            @input="refModalDatePickerOverDueDateEnd.hide()"
          />
        </b-modal> -->
        <b-row class="delete-margin">
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label-for="radioReportType"
              label-cols-md="3"
              label-cols-sm="12"
            >
              <template v-slot:label>
                <div style="margin-top:10px;">
                  เลือกแบบรายงาน
                </div>
              </template>
              <b-form-radio
                v-model="radioReportType"
                value="REPORT1"
                class="mt-1"
              >
                1. เรียงตาม พอร์ต > วันค้าง > เซลล์
              </b-form-radio>
              <b-form-radio
                v-model="radioReportType"
                value="REPORT2"
                class="mt-1"
              >
                2. เรียงตาม พอร์ต > เซลล์ > วันค้าง
              </b-form-radio>
              <b-form-radio
                v-model="radioReportType"
                value="REPORT3"
                class="mt-1"
              >
                3. เรียงตาม เซลล์ > วันค้าง > พอร์ต
              </b-form-radio>
              <b-form-radio
                v-model="radioReportType"
                value="REPORT4"
                class="mt-1"
              >
                4. แบบสั้น
              </b-form-radio>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="delete-margin">
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label-for="branchCode"
              label-cols-md="3"
              label-cols-sm="12"
            >
              <template v-slot:label>
                <div style="margin-top:10px;">
                  พอร์ต
                </div>
              </template>
              <b-input-group v-show="!branchCode">
                <b-form-input
                  id="branchCode"
                  v-model="inputSearchBranch"
                  placeholder="ค้นหาพอร์ต"
                  trim
                  @keydown.enter.native="
                    fncSearchBranch(inputSearchBranch)
                  "
                />
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    @click="fncOpenPopupBranch()"
                  >
                    <feather-icon icon="ListIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <b-input-group v-show="branchCode">
                <b-form-input
                  id="branchCode"
                  :value="branchName"
                  trim
                  disabled
                />
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    @click="fncCancelBranch"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="delete-margin">
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label="เซลล์"
              label-for="salesCode"
              label-cols-md="3"
              label-cols-sm="12"
            >
              <template v-slot:label>
                <div style="margin-top:10px;">
                  เซลล์
                </div>
              </template>
              <b-input-group v-show="!salesCode">
                <b-form-input
                  id="salesCode"
                  v-model="inputSearchSales"
                  placeholder="ค้นหาเซลล์"
                  trim
                  @keydown.enter.native="
                    fncSearchSales(inputSearchSales)
                  "
                />
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    @click="fncOpenPopupSales()"
                  >
                    <feather-icon icon="ListIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <b-input-group v-show="salesCode">
                <b-form-input
                  id="salesCode"
                  :value="salesName"
                  trim
                  disabled
                />
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    @click="fncCancelSales"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <small
                v-if="validateRequireSales"
                class="text-danger"
              >
                กรุณาเลือกเซลล์
              </small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="delete-margin">
          <!-- <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label-for=""
              label-cols-md="3"
              label-cols-sm="12"
            >
              <template v-slot:label>
                <div style="margin-top:10px;">
                  จำนวนวันที่ค้างชำระ
                </div>
              </template>
              <b-input-group class="input-group-date-picker">
                <b-form-input
                  id="inputOverDueDateStart"
                  :value="resolveFormatDate(overDueDateStart)"
                  trim
                  readonly
                  style="width: 45%;"
                  placeholder="วันที่เริ่มต้น"
                  @click="fncShowDatepickerOverDueDateStart()"
                /> -->
          <!-- <b-col>
             <b-form-group
              label="เลขที่คำขอ"
              label-for="overDueDateStart"
              label-cols-md="2"
              label-cols-sm="3"
            >
              <template v-slot:label>
                <div style="margin-top:10px;">
                  จำนวนวันค้างชำระ
                </div>
              </template>
              <b-form-input
                id="overDueDateStart"
                v-model="InputoverDueDateStart"
                placeholder="ตั้งแต่"
                trim
                autocomplete="off"
                style="display: inline; width 30%;"
              />

            </b-form-group>
          </b-col>
          <span
            style="margin-top:35px;margin-right: 10px;margin-left: 150px;"
          >ถึง</span>
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 5 : 12">
            <b-form-group
              label=""
              label-for="overDueDateEnd"
              label-cols-md="3"
              label-cols-sm="12"
            >
              <b-form-input
                id="overDueDateEnd"
                v-model="InputoverDueDateEnd"
                placeholder="ถึง"
                trim
                autocomplete="off"
                style="display: inline; width 30%;"
              />

            </b-form-group>
          </b-col> -->
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label-for="overDueDateStart"
              label-cols-md="3"
              label-cols-sm="12"
            >
              <template v-slot:label>
                <div style="margin-top:10px;">
                  จำนวนวันค้างชำระ
                </div>
              </template>
              <b-form-input
                id="overDueDateStart"
                v-model="InputoverDueDateStart"
                placeholder="0"
                trim
                :style="`display: inline;width: ${$store.state.app.windowWidth ? 40 : 45}%;`"
              />
              <span
                style="margin-top:35px;margin-right: 10px;margin-left: 10px;"
              >ถึง</span>
              <b-form-input
                id="InputoverDueDateEnd"
                v-model="InputoverDueDateEnd"
                placeholder="0"
                trim
                :style="`display: inline;margin-left: 5px;width: ${$store.state.app.windowWidth ? 40 : 45}%;`"
              />

            </b-form-group>
          </b-col>
          <!-- <b-input-group style="width: 45%;">
            <b-form-input
              id="inputOverDueDateEnd"
              :value="resolveFormatDate(overDueDateEnd)"
              trim
              readonly
              style="width: 45%;"
              placeholder="วันที่สิ้นสุด"
              @click="fncShowDatepickerOverDueDateEnd()"
            />
          </b-input-group>
          </b-input-group>
          </b-form-group>
          </b-col> -->
        </b-row>
        <b-row>
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label-for=""
              label-cols-md="3"
              label-cols-sm="12"
            >
              <b-overlay
                :show="overlaySubmitButton"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
              >
                <b-button
                  variant="primary"
                  @click="fncSubmit()"
                >
                  ยืนยัน
                </b-button>
              </b-overlay>
              <b-button
                class="ml-1"
                variant="outline-secondary"
                @click="fncResetForm()"
              >
                ยกเลิก
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </template>
    <template v-else>
      <!-- Component สำหรับแสดงผลลัพธ์ที่ได้จากการ Filter -->
      <aging-report-result
        :type-result="radioReportType"
        :data-result="dataResult"
        @reset-result="fncResetResult()"
      />
    </template>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormRadio, BInputGroup, BFormInput, BInputGroupAppend, BButton, BOverlay,
} from 'bootstrap-vue'
import {
  onMounted, onUnmounted, ref, computed,
} from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import store from '@/store'
// import {
//   VDatePicker,
// } from 'vuetify/lib'
import AgingReportPopupBranch from './aging-report-popup/AgingReportPopupBranch.vue'
import AgingReportPopupSales from './aging-report-popup/AgingReportPopupSales.vue'
import AgingReportResult from './AgingReportResult.vue'
import AgingReportStoreModule from './AgingReportStoreModule'

// Notification
// eslint-disable-next-line import/order
import { useToast } from 'vue-toastification/composition'
// eslint-disable-next-line import/order
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BOverlay,
    AgingReportPopupBranch,
    AgingReportPopupSales,
    AgingReportResult,
    // VDatePicker,
  },

  setup() {
    const APP_STORE_MODULE_NAME = 'appAgingReport'
    const toast = useToast()

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, AgingReportStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    // ประกาศตัวแปร Ref
    // const refModalDatePickerOverDueDateStart = ref(null)
    // const refModalDatePickerOverDueDateEnd = ref(null)

    // ประกาศตัวแปร Textbox
    const inputSearchBranch = ref(null)
    const inputSearchSales = ref(null)

    // ประกาศตัวแปร radio
    const radioReportType = ref('REPORT1')

    // ประกาศตัวแปรสำหรับเก็บค่า
    const branchCode = ref(null)
    const branchName = ref(null)
    const salesCode = ref(null)
    const salesName = ref(null)
    const InputoverDueDateStart = ref(null)
    const InputoverDueDateEnd = ref(null)
    const dataResult = ref([])
    const tempSearchBranch = ref(null)
    const tempSearchSales = ref(null)

    // ประกาศตัวแปร Active / Inactive
    const isPopupBranchActive = ref(false)
    const isPopupSalesActive = ref(false)

    // ประกาศตัวแปรแสดงสถานะ Loading
    const overlaySubmitButton = ref(false)

    // ประกาศตัวแปรอื่นๆ
    const onSubmitStatus = ref(false)

    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */
    /* ฟังก์ชั่น Work Scope */
    const fncFetchWorkScope = () => {
      const payload = {
        action: 'VIEW',
        events: 'SIGNIN',
        function: 'GET',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_USER_WORK_SCOPE`, payload)
        .then(response => {
          /* eslint-disable no-console */
          console.log(response)
        })
        .catch(error => {
          /* eslint-disable no-console */
          console.log(error)
        })
    }

    /* ฟังก์ชั่นเปิด Popup พอร์ต */
    const fncOpenPopupBranch = search => {
      if (search) tempSearchBranch.value = search
      else tempSearchBranch.value = null

      isPopupBranchActive.value = true
    }

    /* ฟังก์ชั่นค้นหาพอร์ต */
    const fncSearchBranch = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: '',
          function: 'GET',
          recactcod: null,
          recstscod: null,
          usrcod: null,
          tabkeyone: 'CPNBRNCOD',
          tabkeytwo: val,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_CONFIG`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.tabdsctha.toLowerCase().indexOf(val) > -1 || item.tabkeytwo.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              branchCode.value = getSearchResult[0].tabkeytwo
              branchName.value = getSearchResult[0].tabdsctha
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupBranch(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นยกเลิกพอร์ต */
    const fncCancelBranch = () => {
      branchCode.value = null
      branchName.value = null
      inputSearchBranch.value = null
    }

    /* ฟังก์ชั่นเปิด Popup เซลล์ */
    const fncOpenPopupSales = search => {
      if (search) tempSearchSales.value = search
      else tempSearchSales.value = null

      isPopupSalesActive.value = true
    }

    /* ฟังก์ชั่นค้นหาเซลล์ */
    const fncSearchSales = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: 'GENERAL',
          function: 'GET',
          username: null,
          access_time: null,
          cpncod_in: null,
          cpnbrncod_in: null,
          accbustyp_in: null,
          accdeawth_in: 'MKT',
          acccod_in: null,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_GET_ACCOUNT_MASTER`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.acccod.toLowerCase().indexOf(val) > -1 || item.account_name.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              salesCode.value = getSearchResult[0].acccod
              salesName.value = getSearchResult[0].account_name
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupSales(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นยกเลิกเซลล์ */
    const fncCancelSales = () => {
      salesCode.value = null
      salesName.value = null
      inputSearchSales.value = null
    }

    // /* ฟังก์ชั่นแสดง DatePicker วันเริ่มต้น */
    // const fncShowDatepickerOverDueDateStart = () => {
    //   refModalDatePickerOverDueDateStart.value.show()
    // }

    // /* ฟังก์ชั่นแสดง DatePicker วันสิ้นสุด */
    // const fncShowDatepickerOverDueDateEnd = () => {
    //   refModalDatePickerOverDueDateEnd.value.show()
    // }

    const validateRequireSales = computed(() => {
      if (!salesCode.value && radioReportType.value === 'REPORT4' && onSubmitStatus.value) return true
      return false
    })

    /* ฟังก์ชั่นเมื่อกดปุ่มยืนยัน */
    // eslint-disable-next-line consistent-return
    const fncSubmit = () => {
      onSubmitStatus.value = true
      if (validateRequireSales.value) return false
      onSubmitStatus.value = false

      overlaySubmitButton.value = true
      setInterval(() => { overlaySubmitButton.value = false }, 5000)

      const accesstime = new Date()

      let reportType = radioReportType.value
      if (radioReportType.value === 'REPORT4') reportType = 'REPORT3' // แก้ให้ report 4 แสดงข้อมูลเดียวกับ report 3

      const payload = {
        action: reportType,
        events: 'AGING',
        function: 'SUBMIT',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        accesstime: null,
        cpncod_in: null, // '0001',
        cpnbrncod_in: branchCode.value,
        saleman_in: salesCode.value ? salesCode.value : null,
        od_day_from: InputoverDueDateStart.value, // Number(InputoverDueDateStart.value),
        od_day_end: InputoverDueDateEnd.value, // Number(InputoverDueDateEnd.value),
        as_date_in: moment(accesstime).format('YYYY-MM-DD'), // ???
      }
      console.log(payload)

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_RPT_AGING`, payload)
        .then(response => {
          console.log(response.data.responseData)
          if (response.data.responseData.length) {
            dataResult.value = response.data.responseData
          } else {
            toast.error({
              component: ToastificationContent,
              props: {
                title: 'แจ้งเตือน',
                icon: 'XCircleIcon',
                variant: 'danger',
                text: 'ไม่พบข้อมูลที่ค้นหา กรุณาลองใหม่อีกครั้ง',
              },
            })
          }
        })
    }

    /* ฟังก์ชั่นเมื่อกดปุ่มยกเลิก จะทำการ reset ข้อมูล */
    const fncResetForm = () => {
      fncCancelBranch()
      fncCancelSales()

      radioReportType.value = 'REPORT1'
      InputoverDueDateStart.value = null
      InputoverDueDateEnd.value = null
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกพอร์ตจาก Popup */
    const fncSelectBranch = data => {
      branchCode.value = data.tabkeytwo
      branchName.value = data.tabdsctha
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกเซลล์นจาก Popup */
    const fncSelectSales = data => {
      salesCode.value = data.acccod
      salesName.value = data.account_name
    }

    /* ฟังก์ชั่นเมื่อกดปุ่มยกเลิก จะทำการ reset ข้อมูล [Result] */
    const fncResetResult = () => {
      dataResult.value = []

      fncResetForm()
    }
    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */

    /* ========== ส่วนการทำงานแสดงผล UI ========== */
    /* กำหนดปีเป็น พ.ศ. */
    const toBuddhistYear = (val, format) => {
      const christianYear = val.format('YYYY')
      // eslint-disable-next-line radix
      const buddhishYear = (parseInt(christianYear) + 543).toString()
      return val
        .format(
          format
            .replace('YYYY', buddhishYear)
            .replace('YY', buddhishYear.substring(2, 4)),
        )
        .replace(christianYear, buddhishYear)
    }

    /* กำหนดรูปแบบ ว/ด/ป */
    const resolveFormatDate = val => (val
      ? toBuddhistYear(moment(val), 'DD/MM/YYYY')
      : null)
    /* ========== ส่วนการทำงานแสดงผล UI ========== */

    /* Life Cycle Onmunted */
    onMounted(() => {
      fncFetchWorkScope()
    })

    return {
      // // ประกาศตัวแปร Ref
      // refModalDatePickerOverDueDateStart,
      // refModalDatePickerOverDueDateEnd,

      // ประกาศตัวแปร Textbox
      inputSearchBranch,
      inputSearchSales,

      // ประกาศตัวแปร radio
      radioReportType,

      // ประกาศตัวแปรสำหรับเก็บค่า
      branchCode,
      branchName,
      salesCode,
      salesName,
      InputoverDueDateStart,
      InputoverDueDateEnd,
      dataResult,
      tempSearchBranch,
      tempSearchSales,

      // ประกาศตัวแปรอื่นๆ
      validateRequireSales,

      // ประกาศตัวแปร Active / Inactive
      isPopupBranchActive,
      isPopupSalesActive,

      // ประกาศตัวแปรแสดงสถานะ Loading
      overlaySubmitButton,

      // ส่วนโค้ดการทำงานฟังก์ชั่น
      fncSearchBranch,
      fncOpenPopupBranch,
      fncCancelBranch,
      fncSearchSales,
      fncOpenPopupSales,
      fncCancelSales,
      // fncShowDatepickerOverDueDateStart,
      // fncShowDatepickerOverDueDateEnd,
      fncSubmit,
      fncResetForm,
      fncSelectBranch,
      fncSelectSales,
      fncResetResult,

      // ส่วนการทำงานแสดงผล UI
      resolveFormatDate,
    }
  },
}
</script>

// <style lang="scss" scoped>
input#inputOverDueDateStart {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

input#inputOverDueDateEnd {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
</style>

<style>
.v-date-picker-title {
    color: black;
}

.v-picker__title.primary {
  margin-top: -80px;
}

.v-date-picker-table.v-date-picker-table--date.theme--light {
  margin-bottom: -30px;
}

.v-picker.v-card.v-picker--date.theme--light {
  margin-left: 0px;
}

button.v-btn.v-btn--active.v-btn--rounded.theme--light.accent {
  color: blue;
}

button.v-btn.v-size--default.v-date-picker-table__current.v-btn--active.v-btn--text.theme--light.accent {
  color: blue;
}

button.v-btn.v-size--default.v-btn--active.theme--light.accent {
  color: blue;
}

.v-picker__title.primary {
  display: none;
}

.modalDatePicker .modal-body {
  padding: 0px;
}

.modalDatePicker .modal-content {
  width: 280px;
}

.v-picker__body.theme--light {
  width: 280px !important;
}

body.modal-open {
  height: 100vh;
  overflow-y: hidden;
}

/* for local only
.delete-margin {
  margin-bottom: -60px !important;
} */
</style>
